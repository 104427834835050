require(["jquery", "domReady!"], function($, doc){
	var _learnq = _learnq || [];

	_learnq.push(['account', 'QJaAhY']);

	(function () {
	var b = document.createElement('script'); b.type = 'text/javascript'; b.async = true;
	b.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'a.klaviyo.com/media/js/analytics/analytics.js';
	var a = document.getElementsByTagName('script')[0]; a.parentNode.insertBefore(b, a);
	})();

	// Enable step elements only once DOM ready
	$('.domready-disabled').each(function(index, element) {
		var $e = $(element);
		if ($e.prop('disabled')) {
			$e.removeProp('disabled');
			$e.removeClass('domready-disabled');
		}
	});

	//product lightbox
	$('section.step-products .bundle-options-wrapper a.action.view-more').on('click', function() {
		$(this).next('.light-box.hllm-lightbox').show(0);
        $('.overlay-new').show(0);
	});
	$('.light-box.hllm-lightbox .overlay').on('click', function() {
		$(this).parent('.light-box.hllm-lightbox').hide(0);
	});
	$('.light-box.hllm-lightbox .content-wrapper a.close').on('click', function(e) {
		$(this).parents('.light-box.hllm-lightbox').hide(0);
        $('.overlay-new').hide(0);
		e.preventDefault();
	});
	function hllm_lightbox_resize() {
		if($('.light-box.hllm-lightbox').html() == undefined)
			return;
		var hllm_lightbox_height = $('.light-box.hllm-lightbox').outerHeight();
		var hllm_screen_height = $(window).height();
		if(hllm_screen_height - hllm_lightbox_height > 50) {
			$('.light-box.hllm-lightbox').css({top: ((hllm_screen_height - hllm_lightbox_height))/2, bottom: 'initial'});
		}
		else {
			$('.light-box.hllm-lightbox').css({top: 20, bottom: 20});
		}
	}
	function pod_price_subtotal_update() {
		var pod_price_subtotal = 0;
		var pod_price_saving = 0;
		var pod_product_total = 0;
		var pod_min_qty = parseInt($('input[name="pod-min-quantity"]').val());
		var pod_max_qty = parseInt($('input[name="pod-max-quantity"]').val());
        var pod_price = parseFloat($('input[name="pod-price"]').val());
        var saving_product = parseFloat($('input[name="saving-product"]').val());
        if(isNaN(saving_product))
            saving_product = 0;
		$('.qty-block input').each(function() {
			var pod_product_number = parseInt($(this).val());
			var chocolate = $(this).closest('.qty-block').children('#chocolate-option').length;
            var chocolate_price = parseFloat($(this).closest('.qty-block').children('#option-price').text());

			if((isFinite(pod_price)) && (isFinite(pod_product_number))){
				if(chocolate)
                    pod_price_subtotal += pod_product_number * chocolate_price;
				else
                    pod_price_subtotal += pod_product_number * pod_price;
            }

			if(!isNaN(pod_product_number) && !chocolate){
                pod_price_saving += (saving_product - pod_price) * pod_product_number;
                pod_product_total += pod_product_number;
			}

		});
		$('.fieldset.tocart-info .col-right .qty').html('$' + pod_price_subtotal.toFixed(2));
		$('.fieldset.tocart-info .col-left .stage-empty').addClass('hide');
		$('.fieldset.tocart-info .col-left .stage-0').addClass('hide');
		$('.fieldset.tocart-info .col-left .stage-1').addClass('hide');
		$('.fieldset.tocart-info .col-left .stage-2').addClass('hide');
		$('.product-details .box-tocart .add-to button').addClass('submit').attr('disabled', 'disabled');
		$('#product-addtosubscription-button').attr('disabled', 'disabled');
		$('.tocart-info .col-left .qty.item').addClass('error');

		$('.fieldset.tocart-info span.qty-saving').html('$' + pod_price_saving.toFixed(2));
		if(pod_product_total == 0) {
			$('.fieldset.tocart-info .col-left .stage-empty').removeClass('hide');
			return;
		}
		if(isNaN(pod_min_qty)) {
			if(isNaN(pod_max_qty)) {
				//nothing set
				$('.fieldset.tocart-info .col-left .qty.item').html(pod_product_total);
				$('.fieldset.tocart-info .col-left .qty.total').html(0);
			} else {
				//can add max qty
				if(pod_product_total > pod_max_qty){
					$('.fieldset.tocart-info .col-left .stage-').removeClass('hide');
					$('.fieldset.tocart-info .col-left .stage-1').removeClass('hide').html('Please select <span class="qty">' + (pod_max_qty - pod_product_total) + '</span>&nbsp; more pods.').show(0);
				}
				else {
					$('.product-details .box-tocart .add-to button').removeClass('submit').removeAttr('disabled');
					$('#product-addtosubscription-button').removeAttr('disabled');
					$('.fieldset.tocart-info .col-left .stage-2').removeClass('hide');
					$('.tocart-info .col-left .qty.item').removeClass('error');
				}
				$('.fieldset.tocart-info .col-left .qty.item').html(pod_product_total);
				$('.fieldset.tocart-info .col-left .qty.total').html(pod_max_qty);
			}
		} else if(isNaN(pod_max_qty)) {
			//can add min qty
			if(pod_product_total < pod_min_qty){
				$('.fieldset.tocart-info .col-left .stage-0').removeClass('hide');
				$('.fieldset.tocart-info .col-left .stage-1').removeClass('hide').html('Please select at least <span class="qty">' + (pod_min_qty) + '</span>&nbsp; pods or <a href="#" class="prev step-2" onclick="window.location.href=podpac_link_step_3; return false;">change your quantity</a>').show(0);
			} else {
				$('.product-details .box-tocart .add-to button').removeClass('submit').removeAttr('disabled');
				$('#product-addtosubscription-button').removeAttr('disabled');
				$('.fieldset.tocart-info .col-left .stage-0').removeClass('hide');
				$('.tocart-info .col-left .qty.item').removeClass('error');
			}
			$('.fieldset.tocart-info .col-left .qty.item').html(pod_product_total);
			$('.fieldset.tocart-info .col-left .qty.total').html(pod_min_qty);
		} else {
			//can add in max and min value
			if(pod_product_total < pod_min_qty) {
				// $('.fieldset.tocart-info .col-left .stage-1').removeClass('hide').html('Please select <span class="qty">' + (pod_min_qty - pod_product_total) + '</span>&nbsp; more pods.').show(0);
				$('.fieldset.tocart-info .col-left .stage-0').removeClass('hide');
				if(pod_max_qty == pod_min_qty)
					$('.fieldset.tocart-info .col-left .stage-1').removeClass('hide').html('Please select <span class="qty min">'+pod_min_qty+'</span> pods or <a href="#" class="prev step-2" onclick="window.location.href=podpac_link_step_3; return false;">change your quantity</a>').show(0);
				else
					$('.fieldset.tocart-info .col-left .stage-1').removeClass('hide').html('Please select between <span class="qty min">'+pod_min_qty+'</span>-<span class="qty">'+pod_max_qty+'</span> pods or <a href="#" class="prev step-2" onclick="window.location.href=podpac_link_step_3; return false;">change your quantity</a>').show(0);
			} else if(pod_product_total > pod_max_qty){
				$('.fieldset.tocart-info .col-left .stage-0').removeClass('hide');
				if(pod_max_qty == pod_min_qty)
					$('.fieldset.tocart-info .col-left .stage-1').removeClass('hide').html('Please select <span class="qty min">'+pod_min_qty+'</span> pods or <a href="#" class="prev step-2" onclick="window.location.href=podpac_link_step_3; return false;">change your quantity</a>').show(0);
				else
					$('.fieldset.tocart-info .col-left .stage-1').removeClass('hide').html('Please select between <span class="qty min">'+pod_min_qty+'</span>-<span class="qty">'+pod_max_qty+'</span> pods or <a href="#" class="prev step-2" onclick="window.location.href=podpac_link_step_3; return false;">change your quantity</a>').show(0);
			} else {
				if(pod_product_total == pod_max_qty) {
					$('.fieldset.tocart-info .col-left .stage-2').removeClass('hide');
				}
				$('.product-details .box-tocart .add-to button').removeClass('submit').removeAttr('disabled');
				$('#product-addtosubscription-button').removeAttr('disabled');
				$('.fieldset.tocart-info .col-left .stage-0').removeClass('hide');
				// $('.fieldset.tocart-info .col-left .stage-1').removeClass('hide');
				$('.tocart-info .col-left .qty.item').removeClass('error');
			}
			$('.fieldset.tocart-info .col-left .qty.item').html(pod_product_total);
			$('.fieldset.tocart-info .col-left .qty.total').html(pod_max_qty);
		}
	}

	function checkReachedMax() {
		var pod_product_total = 0;
		var pod_max_qty = parseInt($('input[name="pod-max-quantity"]').val());

		$('.qty .qty-block input').each(function() {
			var pod_product_number = parseInt($(this).val());
			if(!isNaN(pod_product_number) && !$(this).closest('.qty-block').children('#chocolate-option').length)
				pod_product_total += pod_product_number;
		});

		if(pod_product_total >= pod_max_qty ) {
			return true;
		}
		return false;
	  }


	function pod_pac_change_qty(input, flag) {
		// check the number of the current selection whether reached the max or not
		if(checkReachedMax()) {
			return;
		}

		var pod_pac_product_select_option = $(input).parents('.nested').prev('select');
		var chocolate = input.closest('.qty-block').children('#chocolate-option').length;
		if(pod_pac_product_select_option.length > 0) {
			if((flag == false ) && (parseInt($(input).val()) == undefined || (parseInt($(input).val()) <= 10 && !chocolate) || (parseInt($(input).val()) <= 1 && !chocolate))) {
				$(pod_pac_product_select_option).prop('selectedIndex', 0);
				$(pod_pac_product_select_option).change();
			}
			else {
				// if(parseInt($(input).val()) >= 10)
					// return;
				if($(pod_pac_product_select_option).prop('selectedIndex') == 0) {
					$(pod_pac_product_select_option).prop('selectedIndex', 1);
					$(pod_pac_product_select_option).change();
				}
			}
			// if(parseInt($(input).val()) == 1)
				// $(input).val(0);
		}
	};

	if($('.item-bundle-name td').length >0) {
		$('.item-bundle-name td').each(function() {
			$('.cart.table-wrapper .items thead .item span').text($(this).text());
		});
	}

	if($('.account.customer-subscriptions-edit .product-options-wrapper .qty-block.sub-add > input').length > 0) {
		$('.account.customer-subscriptions-edit .product-options-wrapper .qty-block.sub-add > input').each(function() {
			var hl_data_value = parseInt($(this).attr('data-value'));
			if(hl_data_value != undefined && hl_data_value >= 10)
				$(this).val(parseInt(hl_data_value/10)*10);
		});
	}

	$(document).ready(function(){
		if($('.item-bundle-name td').length >0) {
			$('.item-bundle-name td').each(function() {
				$('.cart.table-wrapper .items thead .item span').text($(this).text());
			});
		}
		if($('.cms-page-view.cms-need-help .account-nav .item a[href*="need-help"]').length > 0) {
			$('.cms-page-view.cms-need-help .account-nav .item a[href*="need-help"]').parent().addClass('current');
		}

		hllm_lightbox_resize();
		$('.step-bottom .image #play-video').on('click', function(ev) {
			$(this).next('iframe').attr('src', ($(this).next('iframe').attr('src') + "&autoplay=1"));
			$(this).hide(0);
			$(this).parents('.image').prev().hide(0);
			ev.preventDefault();
		});

		$('.cms-home .home-section-4 .action.second').click(function(){
		  $('.cms-home section.home-section-4 .content-overlay').fadeIn(300);
		});
		$('.cms-home .home-section-4 .left img').click(function(){
		  $('.cms-home section.home-section-4 .content-overlay').fadeIn(300);
		});
		$('.cms-home .home-section-4 .content-overlay .close').click(function(){
		  $('.cms-home section.home-section-4 .content-overlay').fadeOut(300);
		  $('.cms-home section.home-section-4 iframe').attr('src', $('.cms-home section.home-section-4 iframe').attr('src'));
		});

		function shippingStreet(){
			jQuery('div[name$=".street.0"]').each(function() {
				var pod_pac_street_validate_error = jQuery(this).find('div.mage-error')[0];
				if(pod_pac_street_validate_error != undefined){
					jQuery(this).addClass('_error');
				} else {
					jQuery(this).removeClass('_error');
				}
			});
			setTimeout(shippingStreet, 2000);
		}
		shippingStreet();
		pod_price_subtotal_update();
		$('.step-products .fieldset-bundle-options .qty-block > input').each(function() {
			var pod_pac_product_select_option = $(this).parents('.nested').prev('select');
			if(pod_pac_product_select_option.length > 0) {
				$(pod_pac_product_select_option).prop('selectedIndex', 1);
				$(pod_pac_product_select_option).change();
			}
		});
		setTimeout(function() {
			$('.step-products .fieldset-bundle-options .qty-block > input').each(function() {
				var pod_pac_product_select_option = $(this).parents('.nested').prev('select');
				if(pod_pac_product_select_option.length > 0) {
					if($(pod_pac_product_select_option).prop('selectedIndex') == 1 && parseInt($(this).val()) == 0) {
						$(pod_pac_product_select_option).prop('selectedIndex', 0);
						$(pod_pac_product_select_option).change();
					}
				}
			});
			pod_price_subtotal_update();
		}, 1000);
		//step 4 click event
		//author: dmz
		var hl_account_bundle_option_interval = null;
		var hl_account_bundle_option_interval_counter = 0;
		if($('.account.customer-subscriptions-edit .product-options-wrapper .qty-block.sub-add > input').length > 0) {
			hl_account_bundle_option_interval = setInterval(function() {
				hl_account_bundle_option_interval_counter++;
				$('.account.customer-subscriptions-edit .product-options-wrapper .qty-block.sub-add > input').each(function() {
					var hl_data_value = parseInt($(this).attr('data-value'));
					if(hl_data_value != undefined && hl_data_value >= 10)
						$(this).val(parseInt(hl_data_value/10)*10);
					else
						$(this).val('0');
				});
				if(hl_account_bundle_option_interval_counter == 200)
					clearInterval(hl_account_bundle_option_interval);
			}, 1000);
		}

		$('.account.customer-subscriptions-edit .form-address-edit .fieldset > .field select').click(function() {
			clearInterval(hl_account_bundle_option_interval);
		});
		$('.sub-add .subtrack').on('click', function() {
			clearInterval(hl_account_bundle_option_interval);
			pod_pac_change_qty($(this).next('.input-text.qty'), false);
            if($(this).closest('.qty-block').children('#chocolate-option').length){
                if ($(this).next('.input-text.qty').val() != undefined && parseInt($(this).next('.input-text.qty').val()) >=1) {
					$(this).next('.input-text.qty').val(parseInt($(this).next('.input-text.qty').val()) - 1);
					// UB-40: keep proper state for disabled/enabled when adjusting qty
					$(this).next('.input-text.qty').addClass('qty-disabled').attr('disabled', 'disabled');
				}
			}
            else{
                if($(this).next('.input-text.qty').val() != undefined && parseInt($(this).next('.input-text.qty').val()) >=10) {
					$(this).next('.input-text.qty').val(parseInt($(this).next('.input-text.qty').val()) - 10);
					// UB-64: only disable input if qty is 0. This matches the state of the backend code - qty 0
					// means "do nothing with this simple" - but it doesn't support reducing an existing non-zero
					// qty to 0. TODO.
					if (parseInt($(this).next('.input-text.qty').val()) === 0) {
						$(this).next('.input-text.qty').addClass('qty-disabled').attr('disabled', 'disabled');
					}
				}
			}

			pod_price_subtotal_update();
		});
		$('.sub-add .add').on('click', function() {
			clearInterval(hl_account_bundle_option_interval);
			var pod_pac_before_qty_update = $(this).prev('.input-text.qty').val();
			pod_pac_change_qty($(this).prev('.input-text.qty'), true);
			var pod_pac_after_qty_update = $(this).prev('.input-text.qty').val();
			var pod_max_qty = parseInt($('input[name="pod-max-quantity"]').val());
			var pod_product_total = 0;

			$('.qty .qty-block input').each(function() {
				var pod_product_number = parseInt($(this).val());
				if(!isNaN(pod_product_number) && !$(this).closest('.qty-block').children('#chocolate-option').length)
					pod_product_total += pod_product_number;
			});
			if(!isNaN(pod_max_qty) && !$(this).closest('.qty-block').children('#chocolate-option').length) {
				if(pod_product_total > pod_max_qty) {
					return;
				} else if(pod_product_total == pod_max_qty) {
					pod_price_subtotal_update();
					return;
				}
			}
			if($(this).prev('.input-text.qty').val() != undefined) {
				if(pod_pac_after_qty_update == pod_pac_before_qty_update)
					if ($(this).closest('.qty-block').children('#chocolate-option').length) {
						$(this).prev('.input-text.qty').val(parseInt($(this).prev('.input-text.qty').val()) + 1);
						$(this).prev('.input-text.qty').removeClass('qty-disabled').removeAttr('disabled');
					}
					else {
						$(this).prev('.input-text.qty').val(parseInt($(this).prev('.input-text.qty').val()) + 10);
						$(this).prev('.input-text.qty').removeClass('qty-disabled').removeAttr('disabled');
					}
			}
			// else
				// $(this).prev('.input-text.qty').val(0);
			pod_price_subtotal_update();
		});

        $("#product_addtocart_form").submit(function() {
            $('.actions button.action.tocart').prop('disabled', true);
            $(this).submit();

        });
	});
	$(window).on('resize', function() {
		hllm_lightbox_resize();
	});
});